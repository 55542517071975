export default {
    firebase: {
        projectId: process.env.BC_FIREBASE_PROJECT_ID,
        appId: process.env.BC_FIREBASE_APP_ID,
        databaseURL: process.env.BC_FIREBASE_DATABASE_URL,
        storageBucket: process.env.BC_FIREBASE_STORAGE_BUCKET,
        locationId: process.env.BC_FIREBASE_LOCATION_ID,
        apiKey: process.env.BC_FIREBASE_API_KEY,
        authDomain: process.env.BC_FIREBASE_AUTH_DOMAIN,
        messagingSenderId: process.env.BC_FIREBASE_MESSAGING_SENDER_ID,
        measurementId: process.env.BC_FIREBASE_MEASUREMENT_ID,
    },
}
