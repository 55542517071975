// import { getLocale } from '../locale'
import { parseISO, format } from 'date-fns'

export function formatDate(date) {
    if (!date) {
        return ''
    }
    // Very simple function for now, later deal with locales
    // console.log('dates', getLocale())

    return format(parseISO(date), 'dd-MM-yyyy')
}

export function formatDateTime(date) {
    if (!date) {
        return ''
    }
    // Very simple function for now, later deal with locales
    // console.log('dates', getLocale())
    // return date
    return format(parseISO(date), 'dd-MM-yyyy HH:mm')
}
