<template>
    <section class="hero is-large is-primary is-fullheight header-image jarallax">
        <!-- <div style="padding: 8px;">
			<span>Authenticated: {{ $store.state.authenticated }}</span>
			<span v-if="isAuthenticated"
				>&nbsp;-&nbsp;<a style="color: white;" @click.prevent="logOut">{{ $t('Sign out') }}</a></span
			>
			<span>&nbsp;-&nbsp;</span>
			<router-link to="management" style="color: white;">goto management</router-link>
		</div> -->
        <div class="hero-body" style="padding-top: 3em; padding-bottom: 3em">
            <div class="has-text-centered container">
                <div class="columns is-vcentered">
                    <div class="column">
                        <div style="max-width: 360px; margin: 0 auto">
                            <figure class="image xis-3by5">
                                <img src="../../assets/iphone-sample.png" />
                            </figure>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <h1 class="title is-size-1">Going Walkabout</h1>
                            <h2 class="subtitle">Capture memories, enjoy your travels</h2>
                            <a href="https://itunes.apple.com/app/id1476918517" target="_blank"><img src="../../assets/apple/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <p class="has-text-centered"><i class="fas fa-2x fa-angle-double-down" style="position: relative; bottom: 1em; animation: floating 1.2s infinite"></i></p>
        </div>
    </section>
</template>

<script lang="js">
import { getAuth, signOut } from 'firebase/auth'
import { jarallax } from 'jarallax'

export default {
    name: 'intro',
    computed: {
        isAuthenticated() {
            return this.$store.state.authenticated
        },
    },
    mounted() {
        jarallax(document.querySelectorAll('.jarallax'), {
            speed: 0.6,
        })
    },
    methods: {
        logOut() {
            const auth = getAuth()
            signOut(auth)
        },
    },
}
</script>
