import { getAuth } from 'firebase/auth'

export default {
    get: async (url) => {
        const auth = getAuth()
        var user = auth.currentUser
        if (user) {
            try {
                const token = await user.getIdToken()
                const options = {
                    headers: { Authorization: 'Bearer ' + token },
                }

                let response = await fetch(url, options)
                if (response.ok) {
                    return response.json()
                } else {
                    return Promise.reject(response.status)
                }
            } catch (err) {
                return Promise.reject(err)
            }
        } else {
            return Promise.reject(new Error('No authenticated user'))
        }
    },
    post: async (url, params) => {
        const auth = getAuth()
        var user = auth.currentUser
        if (user) {
            try {
                const token = await user.getIdToken()
                const options = {
                    headers: { Authorization: 'Bearer ' + token },
                    method: 'POST',
                }
                let response = await fetch(url, options)
                if (response.ok) {
                    return response.json()
                } else {
                    return Promise.reject(response.status)
                }
            } catch (err) {
                console.error(err)
            }
        } else {
            return Promise.reject(new Error('No authenticated user'))
        }
    },
}

// fetch(params && params instanceof Object ? `${url}?${new URLSearchParams(params)}` : url, {
//     method: methodType,
//     headers: {
//         'Accept': accept,
//         'Content-Type': dataType,
//     },
//     body: payload,
// }).then((res) => {
//     if (res.ok) {
//         return res.text();
//     } else {
//         throw new Error(res.status);
//     }
// }).then((text) => {
//     const data = text ? JSON.parse(text) : {};
//     return Object.hasOwnProperty.call(data, 'response') ? data.response : data;
// }).then((data) => {
//     success(data);
//     return data;
// }));
