<template>
    <gw-image :src="`/api/trips/${this.tripId}/images/${this.imageId}.jpg`" />
</template>

<script>
export default {
    name: 'x-image',
    props: {
        tripId: {
            type: String,
            required: true,
        },
        imageId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {}
    },
    created() {},
    methods: {},
}
</script>
