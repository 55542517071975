<template>
    <img :src="url" />
</template>

<script>
import { getAuth } from 'firebase/auth'

export default {
    name: 'x-image',
    props: {
        src: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            url: '',
        }
    },
    created() {
        this.getImage()
    },
    methods: {
        async getImage() {
            try {
                const auth = getAuth()
                const user = auth.currentUser
                const token = await user.getIdToken()
                const headers = { Authorization: 'Bearer ' + token }

                const response = await fetch(this.src, { method: 'GET', headers: headers })
                const data = await response.blob()

                const result = await new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onloadend = () => resolve(reader.result)
                    reader.onerror = reject
                    reader.readAsDataURL(data)
                })

                this.url = result
            } catch (e) {
                console.error(`Error: ${e}`)
            }
        },
    },
}
</script>
