<template>
    <div>
        <intro></intro>
        <story></story>
        <x-footer></x-footer>
    </div>
</template>

<script lang="js">
import Intro from '@/components/home/intro'
import Story from '@/components/home/story'
import XFooter from '@/components/footer'

export default {
    name: 'home',
    components: {
        intro: Intro,
        story: Story,
        'x-footer': XFooter,
    },
}
</script>
