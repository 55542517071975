<template>
    <section class="section">
        <div class="container">
            <h1 class="title has-text-centered">The story of Going Walkabout</h1>
            <p class="content has-text-centered">
                {{
                    $t(
                        "One day a week I work on Going Walkabout, a mobile travel diary. I love to travel and go to new places, part of traveling is sharing experiences and capturing memories. None of the existing solutions fulfill my needs and now it's time to do something about it. I document the progress on YouTube, you see me making functional and technical decisions, implement and test the functionality. It gives you insights in what needs to be done when creating an app."
                    )
                }}
            </p>
            <p class="content"></p>
        </div>
        <div class="is-fullhd container">
            <p class="content has-text-centered has-text-weight-semibold">
                <a href="https://www.youtube.com/goingwalkabout" target="_blank">{{ $t('See the full story of Going Walkabout') }}</a>
            </p>

            <p class="content has-text-centered">
                <a class="button is-primary" href="https://www.youtube.com/channel/UCK1zKCoLY61Xb9UgGyjGarQ?sub_confirmation=1" target="_blank"
                    ><strong>{{ $t('Subscribe') }}</strong></a
                >
            </p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'gwa',
    data() {
        return {}
    },
    created() {},
}
</script>
