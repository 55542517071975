<template>
    <main>
        <section class="columns">
            <div class="column is-offset-1 is-10">
                <div class="content" style="padding: 1em">
                    <markdown slug="terms"></markdown>
                </div>
            </div>
        </section>
        <x-footer></x-footer>
    </main>
</template>

<script>
import Markdown from '@/components/system/markdown'
import XFooter from '@/components/footer'

export default {
    name: 'terms',
    components: {
        markdown: Markdown,
        'x-footer': XFooter,
    },
}
</script>
