import { createApp } from 'vue'
import App from './app'
import router from './router'
import store from './store'

import { initializeApp } from 'firebase/app'

import { getAuth, onAuthStateChanged } from 'firebase/auth'

import 'cookieconsent'

import Formatter from './plugins/format'
import Translator from './plugins/translator'
import { translate } from './system/translator'

import Image from '@/components/common/image'
import TripImage from '@/components/common/trip-image'

import config from './config'

const fb = initializeApp(config.firebase)

// wait for first firebase auth change before setting up vue
let app

const auth = getAuth(fb)
onAuthStateChanged(auth, (user) => {
    if (user) {
        store.commit('authenticate')
        console.log(`Authenticated: ${user.uid}`)
    } else {
        store.commit('deauthenticate')
        console.log('NOT Authenticated')
    }
    if (!app) {
        app = createApp(App)
        app.use(store)
        app.use(router).use(Formatter).use(Translator).component('gw-image', Image).component('gw-trip-image', TripImage).mount('#app')
    }
})

document.addEventListener('DOMContentLoaded', () => {
    if (window.location.pathname !== '/auth/action') {
        window.cookieconsent.initialise({
            palette: {
                popup: {
                    background: '#ebfeff',
                    text: '#00ACB8',
                },
                button: {
                    background: '#00ACB8',
                    text: '#ffffff',
                },
            },
            position: 'bottom',
            theme: 'classic',
            content: {
                message: translate('This website uses cookies to ensure you get the best experience on our website'),
                dismiss: translate('Got it!'),
                link: translate('Our privacy policy.'),
                href: '/privacy',
                target: '_self',
            },
        })
    }
})
