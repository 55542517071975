import { translate } from '../system/translator'

const Translator = {
    install(app, options) {
        if (!options) {
            options = {}
        }
        app.config.globalProperties.$t = translate
    },
}
export default Translator
