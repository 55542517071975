import { Format } from '../system/formatting'

const Formatter = {
    install(app, options) {
        if (!options) {
            options = {}
        }
        app.config.globalProperties.$format = new Format()
    },
}
export default Formatter
