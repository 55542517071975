<template>
    <div>
        <section class="section">
            <div class="container">
                <h1 class="title has-text-centered">Not found</h1>
            </div>
        </section>
    </div>
</template>

<script>
export default {}
</script>
