import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/home'
import Login from './views/system/login'
import Register from './views/system/register'
import Terms from './views/terms'
import Privacy from './views/privacy'
import Links from './views/links'
import Auth from './views/auth'
import _403 from './views/errors/403'
import _404 from './views/errors/404'
import api from './system/api'
import store from './store'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            props: true,
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
        },
        {
            path: '/terms',
            name: 'terms',
            component: Terms,
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: Privacy,
        },
        {
            path: '/auth/action',
            name: 'auth',
            component: Auth,
        },
        {
            path: '/links/invites',
            name: 'links',
            component: Links,
        },
        {
            path: '/management',
            name: 'management',
            component: () => import(/* webpackChunkName: "management" */ './views/management.vue'),
            meta: {
                auth: true,
                admin: true,
            },
            children: [
                {
                    path: 'users',
                    component: () => import(/* webpackChunkName: "management" */ './components/management/users.vue'),
                },
                {
                    path: 'trips',
                    component: () => import(/* webpackChunkName: "management" */ './components/management/trips.vue'),
                },
                {
                    path: 'trips/:tripId',
                    name: 'management-trip',
                    component: () => import(/* webpackChunkName: "management" */ './components/management/trip.vue'),
                    props: true,
                },
                {
                    path: 'flags',
                    component: () => import(/* webpackChunkName: "management" */ './components/management/flags.vue'),
                },
            ],
        },
        {
            path: '/about',
            name: 'about',
            component: () => import(/* webpackChunkName: "about" */ './views/about'),
        },
        {
            path: '/403',
            name: '403',
            component: _403,
        },
        {
            path: '/404',
            name: '404',
            component: _404,
        },
        {
            path: '/*',
            redirect: '404',
        },
    ],
})

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.auth)
    const requiresAdmin = to.matched.some((record) => record.meta.admin)

    if (requiresAuth || requiresAdmin) {
        if (!store.state.authenticated) {
            return next({ name: 'login', params: { redirect: to.fullPath }, replace: true })
        }
    }
    if (requiresAdmin) {
        try {
            const response = await api.get('/api/admin/permissions')
            if (response.permissions === true) {
                return next()
            } else {
                return next({ name: '403', replace: true })
            }
        } catch (e) {
            console.error(` - Something went wrong: ${e}`)
            return next('/')
        }
    }

    next()
})

// router.afterEach((to) => {
// 	console.log(to)
// 	firebase.analytics().logEvent('page_view', {
// 		page_title: to.path,
// 		// page_path: to.path,
// 	})
// })

export default router
