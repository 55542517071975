<template>
    <main id="app">
        <router-view />
    </main>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
</style>
