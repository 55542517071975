<template>
    <section class="hero is-large is-primary is-fullheight header-image">
        <div style="padding: 150px 20px">
            <div class="container">
                <div class="columns">
                    <div class="column is-offset-3 is-6" style="padding-bottom: 60px">
                        <div class="box">
                            <h3 class="title has-text-centered has-text-grey">{{ $t('Login') }}</h3>
                            <v-form @submit="onSubmit" :validation-schema="schema" v-slot="{ meta }">
                                <div class="field">
                                    <div class="control has-icons-left has-icons-right">
                                        <v-field name="email" v-slot="{ field, meta }">
                                            <input type="email" class="input is-medium" :placeholder="$t('Email')" v-bind="field" />
                                            <span class="icon is-left">
                                                <i class="fas fa-envelope"></i>
                                            </span>
                                            <span v-show="meta.dirty && meta.valid" class="icon is-right">
                                                <i class="fas fa-check"></i>
                                            </span>
                                        </v-field>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="control has-icons-left has-icons-right">
                                        <v-field name="password" v-slot="{ field, meta }">
                                            <input type="password" class="input is-medium" :placeholder="$t('Password')" v-bind="field" />
                                            <span class="icon is-left">
                                                <i class="fas fa-unlock-alt"></i>
                                            </span>
                                            <span v-show="meta.dirty && meta.valid" class="icon is-right">
                                                <i class="fas fa-check"></i>
                                            </span>
                                        </v-field>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="message is-danger" v-if="message.length > 0">
                                        <div class="message-body is-size-7">
                                            {{ message }}
                                        </div>
                                    </div>
                                </div>
                                <button class="button is-fullwidth is-info is-medium" :disabled="!meta.valid">login with email</button>
                                <div class="has-text-centered" style="margin-top: 20px">Don't have an account? <router-link to="/register">Register</router-link></div>
                            </v-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { Field, Form, defineRule } from 'vee-validate'
import { required, email, min } from '@vee-validate/rules'

defineRule('required', required)
defineRule('email', email)
defineRule('min', min)

export default {
    name: 'login',
    components: {
        'v-form': Form,
        'v-field': Field,
    },
    props: {
        redirect: {
            type: String,
            default: '',
        },
    },
    data() {
        const schema = {
            email: 'required|email',
            password: 'required|min:6',
        }

        return {
            message: '',
            schema,
        }
    },
    methods: {
        onSubmit(values) {
            const auth = getAuth()
            signInWithEmailAndPassword(auth, values.email, values.password)
                .then(() => {
                    this.$nextTick(() => {
                        const redirect = this.redirect !== '' ? this.redirect : '/'
                        return this.$router.replace(redirect, () => {}) // TODO: breaks in next release
                    })
                })
                .catch((error) => {
                    this.message = error.message
                    console.error(' Error: ' + this.message)
                })
        },
    },
}
</script>
