<template>
    <div>
        <div v-html="text"></div>
    </div>
</template>

<script>
import { marked } from 'marked'

export default {
    name: 'markdown',
    props: {
        slug: String,
    },
    data() {
        return {
            text: null,
        }
    },
    async created() {
        try {
            let response = await fetch(`/docs/${this.slug}`)
            if (response.ok) {
                const result = await response.text()
                this.text = marked.parse(result)
            }
        } catch (err) {
            console.error(err)
        }
    },
}
</script>
